
import RedReflection from "../media/artpage/Portraits/RedReflectionbyLunaSmith.jpg";
import BabyInTheBasket from "../media/artpage/Portraits/NewBornBabybyLunaSmith.jpg";
import Nurse from "../media/artpage/Portraits/NursebyLunaSmith.jpg";
import GenesisOfLife from "../media/artpage/Portraits/GenesisOfLife.jpg";
import FlamencoDancer from "../media/artpage/Portraits/FlamencoByLunaSmith.jpg";
import Morning from "../media/artpage/Portraits/MorningyLunaSmith.jpg";


import RedReflectionBg from "../media/artpage/Portraits/RedReflectionbyLunaSmith_background.jpg";
import BabyInTheBasketBg from "../media/artpage/Portraits/NewBorn_background.jpg";
import NurseBg from "../media/artpage/Portraits/Nurse_background.jpg";
import GenesisOfLifeBg from "../media/artpage/Portraits/GenesisOfLife_background.jpg";
import FlamencoDancerBg from "../media/artpage/Portraits/FlamencoByLunaSmith-background.jpg";
import MorningBg from "../media/artpage/Portraits/Morning_background.jpg";

export const portraitsCollection = [
    {    
        src: BabyInTheBasket,
        srcBg: BabyInTheBasketBg,
        alt: "Newborn in the Basket by Luna Smith - oil painting",
        about: "Portrait",
        keyWords: "baby, family, child, portrait",
        title: "Newborn",
        description:
            "This painting by Scottish artist Luna Smith portrays a serene and tender scene of a newborn baby peacefully sleeping in a woven basket. The baby, with delicate, closed eyes and rosy cheeks, exudes tranquility and innocence. The soft, muted tones of the background complement the subject, creating a warm and gentle atmosphere. The texture and brushwork highlight the artist's attention to detail, particularly in the baby's hair and the intricate weave of the basket. The overall composition conveys a sense of calmness and purity, capturing a fleeting yet precious moment of early life.",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/9AQeWd6OvdrJdVudSv",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=UFQFCHAUM2JAQ",
        dimension: "40 X 30 X 1.5 cm",
    },
    {    
        src: Nurse,
        srcBg: NurseBg,
        alt: "Nurse by Luna Smith - oil painting",
        about: "Portrait",
        keyWords: "woman, hospital, nhs, portrait, covid, sick, health",
        title: "Nurse",
        description:
            "This painting by Scottish artist Luna Smith features a poignant and expressive portrait of a nurse, symbolizing the frontline healthcare workers during challenging times. The nurse's face, partially obscured by a blue surgical mask and head covering, reveals striking, expressive eyes that convey a mix of determination and fatigue. The background, rendered in abstract textures and a palette of blues and whites, enhances the dramatic intensity of the subject. The presence of red specks scattered throughout the composition suggests the constant threat and presence of illness. The artist's brushwork and use of color emphasize the emotional weight carried by healthcare professionals, capturing a moment of resilience and dedication in the face of adversity.",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/bIYaFX2yf0EX9Fe7u6",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=QBMC2Y4JN7HV6",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: RedReflection,
        srcBg: RedReflectionBg,
          alt: "Red Reflection by Luna Smith - oil painting",
          about: "Portrait",
          keyWords: "woman, girl, period, sadness, feeling, child, toys, room, portrait, red",
          title: "Red Reflection",
          description: "The painting captures a poignant moment of a young girl experiencing the physical and mental pain of her period. The red suggests the blood and rawness of the experience, while the white might represent purity or isolation. This powerful artwork evokes empathy and highlights the silent struggles many girls and women face.",
          button: "Sold",
          price: "£155",
          link: "",
          linkPayPal: "",
          dimension: "24.5 X 29.5 X 1.5 cm",
      },
      {    
        src: GenesisOfLife,
        srcBg: GenesisOfLifeBg,
        alt: "Genesis Of Life by Luna Smith - oil painting",
        about: "Portrait",
        keyWords: "portrait, woman, girl, feminity, mother, motherhood, mother's day, birth, preganancy, goddess, Valknut, symbols, Norse Mythology, celtic, Scotland, Scottish, Nordic",
        title: "Genesis Of Life",
        description:
          "This painting beautifully captures the essence of creation through a figure embodying a Norse goddess, cradling a vibrant, swirling universe within her. The Valknut and Celtic patterns adorning her form represent the ancient symbols of power, life, and death in Norse mythology. The wings, reminiscent of a Valkyrie, and the organic elements around her emphasize both divine protection and life’s fertility. Her serene expression conveys a deep connection to the mysteries of birth and the cyclical nature of existence, highlighting the balance between creation, wisdom, and the interconnectedness of life and the cosmos.",
        button: "Card",
        price: "£6500",
        link: "https://buy.stripe.com/fZedS91ub4Vd3gQ16L",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JCSVPLDYJ483Y",
        dimension: "76 X 102 X 1.5 cm",
      },
      {    
        src: FlamencoDancer,
        srcBg: FlamencoDancerBg,
        alt: "Flamenco Dancer by Luna Smith - oil painting",
        about: "Portrait",
        keyWords: "portrait, woman, girl, feminity, dance, Spain, Argentina, goddess, dress, red",
        title: "Flamenco Dancer",
        description:
          "This oil painting features a dynamic and expressive depiction of a flamenco dancer in mid-motion. The dancer, adorned in a flowing, deep red dress, exudes passion and intensity. The vibrant and textured brushstrokes capture the movement of her ruffled dress as it swirls, while the background is abstract, with splashes of gray, yellow, and white that emphasize the energy and emotion of the scene. The figure's face is serene, but her body conveys a sense of fierce determination and grace. The bold contrasts and vivid colors evoke the drama and emotion of the flamenco dance.",
        button: "Card",
        price: "£990",
        link: "https://buy.stripe.com/8wMeWd1ub1J19Fe7uI",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=LN5YEPBPUWABU",
        dimension: "40 X 51 X 1.5 cm",
      },
      {    
        src: Morning,
        srcBg: MorningBg,
        alt: "Morning by Luna Smith - oil painting",
        about: "Portrait",
        keyWords: "portrait, woman, girl, feminity, sea, ocean, seaside, goddess, Valknut, hat, walk, runnin, celtic, Scotland, Scottish, Nordic",
        title: "Morning",
        description:
          "This oil painting captures a serene yet dynamic moment of a woman walking along the seashore, with her dark hair streaming in the wind. She wears a vibrant red dress, which flows around her in loose, expressive brushstrokes, adding a sense of motion and freedom. In her hands, she holds a large sun hat, its soft yellow tones contrasting with the rich reds of her dress. The background consists of soft blues, greens, and whites, evoking the light and texture of the ocean waves. The overall feeling is one of tranquility and connection with nature, as the figure moves gracefully through this coastal scene.",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/00gaFXa0HgDVaJi4iv",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4NS4XN25892YS",
        dimension: "30 X 40 X 1.5 cm",
      },
]