import ZShadows from "../media/artpage/Shadows/Z-in-Shadows-by-Luna-Smith.jpg";
import BShadows from "../media/artpage/Shadows/B-in-Shadows-by-Luna-Smith.jpg";
import GShadows from "../media/artpage/Shadows/G-in-Shadows-by-Luna-Smith.jpg";
import JShadows from "../media/artpage/Shadows/J-in-Shadows-by-Luna-Smith.jpg";
import LShadows from "../media/artpage/Shadows/L-in-Shadows-by-Luna-Smith.jpg";
import MShadows from "../media/artpage/Shadows/M-in-Shadows-by-Luna-Smith.jpg";
import OShadows from "../media/artpage/Shadows/O-in-Shadows-by-Luna-Smith.jpg";
import RShadows from "../media/artpage/Shadows/R-in-Shadows-by-Luna-Smith.jpg";
import VShadows from "../media/artpage/Shadows/V-in-Shadows-by-Luna-Smith.jpg";
import ZShadowsBg from "../media/artpage/Shadows/ZShadows.jpg";
import BShadowsBg from "../media/artpage/Shadows/BShadows.jpg";
import GShadowsBg from "../media/artpage/Shadows/GShadows.jpg";
import JShadowsBg from "../media/artpage/Shadows/JShadows.jpg";
import LShadowsBg from "../media/artpage/Shadows/LShadows.jpg";
import MShadowsBg from "../media/artpage/Shadows/MShadows.jpg";
import OShadowsBg from "../media/artpage/Shadows/OShadows.jpg";
import RShadowsBg from "../media/artpage/Shadows/RShadows.jpg";
import VShadowsBg from "../media/artpage/Shadows/VShadows.jpg";
import PosterShadows from "../media/artpage/Shadows/Shadows-past-exhibition.jpg";


export const shadowsCollection = [
  {
    src: PosterShadows,
    srcBg: "",
    alt: "Shadows - art exhibition by Luna Smith",
    about: "Past Exhibition",
    keyWords: "",
    title: "Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Aritst",
    price: "",
    link: "/artist",
    linkPayPal: "",
    dimension: "",
  },
  {
    src: ZShadows,
    srcBg: ZShadowsBg,
    alt: "Z in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "Z in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/dR6g0hgp573lg3C6ps",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4F8JETRK3F2CE",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: BShadows,
    srcBg: BShadowsBg,
    alt: "B in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "B in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/8wM01jegX1J15oYaFJ",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6MLJBVRWKDX8A",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: GShadows,
    srcBg: GShadowsBg,
    alt: "G in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "G in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/4gw9BT3Cj87p5oY3di",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=58CQSHX9YL5AC",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: VShadows,
    srcBg: VShadowsBg,
    alt: "V in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "V in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Sold",
    price: "£650",
    link: "",
    linkPayPal: "",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: JShadows,
    srcBg: JShadowsBg,
    alt: "J in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "J in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/3cs5lDgp54Vd3gQ7tA",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6Z3Q2S22ANKDU",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: LShadows,
    srcBg: LShadowsBg,
    alt: "L in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "L in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/4gw01j6Ov5Zh9Fe3dl",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=3FL4CPJKPAL5U",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: MShadows,
    srcBg: MShadowsBg,
    alt: "M in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "M in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/cN2g0h4Gn1J16t23dm",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=68XYQXHV89UFQ",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: OShadows,
    srcBg: OShadowsBg,
    alt: "O in Shadows by Luna Smith - oil painting",
    about: "Upcoming Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "O in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/00gcO55KrgDV5oY7tD",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=229WW8RSHH9QY",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: RShadows,
    srcBg: RShadowsBg,
    alt: "R in Shadows by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "shadow, abstract, city, pavement",
    title: "R in Shadows",
    description:
      "Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let Luna Smith's shadow art collection be a source of inspiration and wonder, as you discover the beauty of the unseen.",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/4gw15n4GnbjBaJi6pA",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=L47SADMMF2L6J",
    dimension: "30 X 40 X 1.5 cm",
  },
];
