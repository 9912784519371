import OdinHome from "../media/artpage/Landscapes/OdinsReverie.jpg";
import UnderTheCelestialVeil from "../media/artpage/Landscapes/UnderTheCelestialVeil.jpg";
import BowFiddleRock from "../media/artpage/Landscapes/Bow-Fiddle-Rock-by-Luna-Smith.jpg";
import FingalCave from "../media/artpage/Landscapes/Fingal's Aquatic Sonata.jpg";
import GuardianTree from "../media/artpage/Landscapes/Guardian-Tree-by-Luna-Smith.jpg";
import HawaiianBeach from "../media/artpage/Landscapes/Hawaiian-Beach-by-Luna-Smith.jpg";
import MeltingBlueMountains from "../media/artpage/Landscapes/Melting-Blue-Mountains-by-Luna-Smith.jpg";
import Paradise from "../media/artpage/Landscapes/Paradise-by-Luna-Smith.jpg";
import SecretGarden from "../media/artpage/Landscapes/Secret-Garden-by-Luna-Smith.jpg";
import Savannah from "../media/artpage/Landscapes/Savannah-Beach,-Georgia-by-Luna-Smith.jpg";
import SnowyMountRainier from "../media/artpage/Landscapes/Snowy-Mount-Rainier-by-Luna-Smith.jpg";
import GoldenTrees from "../media/artpage/Landscapes/Shimmering-Golden-Trees-by-Luna-Smith.jpg";
import Diabaig from "../media/artpage/Landscapes/The-Frigid-Whisper-of-Diabaig-by-Luna-Smith.jpg";
import LonelyDragon from "../media/artpage/Landscapes/The-Lonely-Dragon-by-Luna-Smith.jpg";
import VikingSunset from "../media/artpage/Landscapes/Viking-Sunset-by-Luna-Smith.jpg";
import WinterCottage from "../media/artpage/Landscapes/Winter-Cottage-by-Luna-Smith.jpg";
import WinterBreeze from "../media/artpage/Landscapes/Winter-Breeze-by-Luna-Smith.jpg";
import Waterfall from "../media/artpage/Landscapes/Waterfall-by-Luna-Smith.jpg";
import Arran from "../media/artpage/Landscapes/Grasslands-of-Arran-by-Luna-Smith.jpg";
import GlenEtive from "../media/artpage/Landscapes/The-Song-of-Glen-Etive-by-Luna-Smith.jpg";
import MeltingAutumnSunrise from "../media/artpage/Landscapes/Melting-Autumn-Sunrise-by-Luna-Smith.jpg";
import Autumn from "../media/artpage/Landscapes/Autumn-by-Luna-Smith.jpg";
import AutumnReflection from "../media/artpage/Landscapes/Autumn-Reflection-by-Luna-Smith.jpg";
import ItalianAlps from "../media/artpage/Landscapes/Italian-Alps-by-Luna-Smith.jpg";
import BlueHollow from "../media/artpage/Landscapes/The-Blue-Hollow-by-Luna-Smith.jpg";
import ElgolBeach from "../media/artpage/Landscapes/Sunrise-at-Elgol-Beach-by-Luna-Smith.jpg";
import AutumnSunset from "../media/artpage/Landscapes/Autumn-Sunset-by-Luna-Smith.jpg";
import WhenWinterMeetsSummer from "../media/artpage/Landscapes/Winter-Summer-by-Luna-Smith.jpg";
import ScottishDream from "../media/artpage/Landscapes/Scottish-Dream-by-Luna-Smith.jpg";
import TrappedWind from "../media/artpage/Landscapes/Trapped-in-the-Silent-Wind-by-Luna-Smith.jpg";
import FirstWalk from "../media/artpage/Landscapes/The-First-Walk-by-Luna-Smith.jpg";
import MiniWaterfall from "../media/artpage/StillLife/MiniWaterfallByLunaSmith.jpg";

import OdinHomeBg from "../media/artpage/Landscapes/Odin'sHome.jpg";
import UnderTheCelestialVeilBg from "../media/artpage/Landscapes/UnderTheCelestialVeil_background.jpg";
import BowFiddleRockBg from "../media/artpage/Landscapes/BowFiddleRockBG.jpg";
import FingalCaveBg from "../media/artpage/Landscapes/FingalsAquaticSonata.jpg";
import GuardianTreeBg from "../media/artpage/Landscapes/GuardianTree.jpg";
import HawaiianBeachBg from "../media/artpage/Landscapes/HawaiianBeach.jpg";
import MeltingBlueMountainsBg from "../media/artpage/Landscapes/BlueMountains.jpg";
import ParadiseBg from "../media/artpage/Landscapes/Paradise.jpg";
import SecretGardenBg from "../media/artpage/Landscapes/SecretGarden.jpg";
import SavannahBg from "../media/artpage/Landscapes/SavannahGeorgia.jpg";
import SnowyMountRainierBg from "../media/artpage/Landscapes/SnowyMount.jpg";
import GoldenTreesBg from "../media/artpage/Landscapes/GoldenTrees.jpg";
import DiabaigBg from "../media/artpage/Landscapes/Diabiag.jpg";
import LonelyDragonBg from "../media/artpage/Landscapes/LonelyDragon.jpg";
import VikingSunsetBg from "../media/artpage/Landscapes/VikingSunset.jpg";
import WinterCottageBg from "../media/artpage/Landscapes/Winter-Cottage.jpg";
import WinterBreezeBg from "../media/artpage/Landscapes/WinterBreeze.jpg";
import WaterfallBg from "../media/artpage/Landscapes/Waterfall.jpg";
import ArranBg from "../media/artpage/Landscapes/Arran.jpg";
import GlenEtiveBg from "../media/artpage/Landscapes/GlenEtive.jpg";
import MeltingAutumnSunriseBg from "../media/artpage/Landscapes/MeltingAutumn.jpg";
import AutumnBg from "../media/artpage/Landscapes/Autumn.jpg";
import AutumnReflectionBg from "../media/artpage/Landscapes/AutumnReflection.jpg";
import ItalianAlpsBg from "../media/artpage/Landscapes/Italian-Alps.jpg";
import BlueHollowBg from "../media/artpage/Landscapes/BlueHollow.jpg";
import ElgolBeachBg from "../media/artpage/Landscapes/ElgolBeach.jpg";
import AutumnSunsetBg from "../media/artpage/Landscapes/AutumnSunset.jpg";
import WhenWinterMeetsSummerBg from "../media/artpage/Landscapes/WinterSummer.jpg";
import ScottishDreamBg from "../media/artpage/Landscapes/ScottishDream.jpg";
import TrappedWindBg from "../media/artpage/Landscapes/TrappedWind.jpg";
import FirstWalkBg from "../media/artpage/Landscapes/FirstWalk.jpg";
import MiniWaterfallBg from "../media/artpage/StillLife/MiniWaterfallByLunaSmith-background.jpg";

export const landscapesCollection = [
  {    
    src: Diabaig,
    srcBg: DiabaigBg,
    alt: "The Frigid Whisper of Diabaig by Luna Smith - oil painting",
    about: "Past Exhibition",
    keyWords: "landscape, landscapes, scenery, clouds, winter, spring, frost, mountains, hills, seachore, rocky beach, celtic, Highlands Scotland, Scottish, Nordic, ocean, sea",
    title: "The Frigid Whisper of Diabaig",
    description:
      "The Scottish landscape is a stunning portrayal of the tranquil scene of the sea loch and stunning rocks, covered in moss and the soft light of the first day of spring. The water is calm, reflecting the surrounding landscape and creating a sense of depth. In the background, the snowy hills rise up, creating a dramatic contrast against the bright blue sky. The intricate details, the visible brushstrokes, and the use of light and shadow create a lifelike quality, making this a truly mesmerizing piece of art.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/14k15n7Sz2N57x6eXd",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=WXTGXQ47RZ2JE",
    dimension: "60 X 50 X 1.5 cm",
  },
  {    
    src: GuardianTree,
    srcBg: GuardianTreeBg,
    alt: "Guardian Tree by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, bird, tree, trunk, seachore, beach, Norse Mythology, god, goddess, celtic, Scotland, Scottish, Nordic, ocean, sea",
    title: "Guardian Tree",
    description:
      "The focal point of the Guardian Tree painting is an old tree trunk, depicted as a guardian tree, adorned with rich colors and bold brushstrokes, creating a mesmerizing texture that draws the eye in. In the background, you can see clouds in the sky, painted in an array of a sunrise, adding a pop of magic to the piece. The intricate details and the visible texture of every brushstroke in the painting give it a lifelike quality and make it a truly captivating piece of art.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/bIYaFXc8P2N59FebL0",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=DMBY85P2VMH7W",
    dimension: "30 X 40 X 1.5 cm",
  },
  {    
    src: UnderTheCelestialVeil,
    srcBg: UnderTheCelestialVeilBg,
    alt: "Under The Celestial Veil by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, universe, night, stonehedge, stones, rocks, England, mystery, Norse Mythology, celtic, Scotland, Scottish, Nordic",
    title: "Under The Celestial Veil",
    description:
      "This artwork captures the timeless mystery of Stonehenge beneath a vast, star-filled sky. The painting contrasts the ancient monument with a dramatic celestial backdrop, highlighting the enigmatic connection between the earth and the cosmos.",
    button: "Card",
    price: "£6500",
    link: "https://buy.stripe.com/4gw15n5KrdrJdVubLo",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=X8ESENGNVZZRC",
    dimension: "76 X 102 X 1.5 cm",
  },
  {    
    src: GoldenTrees,
    srcBg: GoldenTreesBg,
    alt: "Shimmering Golden Trees by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, tree, forest, woods, lake, snow, snowy, wintery, celtic, Scotland, Scottish, Nordic, winter",
    title: "Shimmering Golden Trees",
    description:
      "Shimmering Golden Trees is an oil painting that depicts a winter forest at the lake. The technique used in this artwork creates an almost impressionistic feel, with the use of finger-work giving the snowy landscape a unique, almost textured appearance. The art piece captures the serenity of a winter forest and the sparkling effect of the golden paint on the trees gives a magical touch to the scene.",
    button: "Card",
    price: "£1200",
    link: "https://buy.stripe.com/9AQ7tL6Ov4VdaJi3ey",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=MZ6H9GCTEC5FG",
    dimension: "60 X 50 X 1.5 cm",
  },
  {    
    src: OdinHome,
    srcBg: OdinHomeBg,
    alt: "Odin's Reverie by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, sunset, sunrise, animals, bird, tree, trunk, seachore, beach, owl, Norse Mythology, god, celtic, Scotland, Scottish, Nordic, ocean, sea",
    title: "Odin's Reverie",
    description:
      "The scene is bathed in a profusion of colors, with the sky taking center stage as a riot of hues dance across the canvas. Fluffy clouds, painted in vivid tones, dominate the expansive sky, casting a playful and joyous atmosphere over Odin's tranquil abode. The artist's skillful use of small brushstrokes not only adds depth and complexity to the overall composition but also imparts a sense of movement and life to the clouds, as if they are animated by a celestial dance. The tree in which Odin perches is a masterpiece of detail, with every branch intricately rendered. The owl, a symbol of wisdom and mystery, exudes a regal presence, its eyes reflecting an ancient and knowing gaze. The seashore below is depicted with a harmonious blend of colors, capturing the ebb and flow of the tide with a sense of serenity.",
    button: "Card",
    price: "£4500",
    link: "https://buy.stripe.com/6oE9BT4GngDVcRqg0B",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=MPFVDQQ2EHY5Q",
    dimension: "59 X 84 X 1.5 cm",
  },
  {    
    src: LonelyDragon,
    srcBg: LonelyDragonBg,
    alt: "The Lonely Dragon by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, mountain, animals, mythical beast, flying, reptile, Norse Mythology, snow, snowy, winter, wintery, celtic, Scotland, Scottish, Nordic, mountains",
    title: "The Lonely Dragon",
    description:
      "The Lonely Dragon painting is a stunning depiction of a majestic dragon soaring through the skies. The dragon is massive, with wings spread wide, flying over high, snow-covered mountains and clouds. The dragon is depicted as a solitary figure, giving the painting a feeling of loneliness and solitude. The snowy mountains and clouds create a dramatic backdrop for the dragon, with the white and blue hues contrasting against the dragon's deep, dark scales. The brushwork is masterful, with each stroke capturing the dragon's strength, grace, and beauty.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/3cs8xP3CjcnFbNm4iD",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9QFS8NJ3VL6DW",
    dimension: "60 X 50 X 1.5 cm",
  },
  {    
    src: SecretGarden,
    srcBg: SecretGardenBg,
    alt: "Secret Garden by Luna Smith",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, sunset, sunrise, flowers, flower, grass, trees, Norse Mythology, celtic, Scotland, Scottish, Nordic,runes",
    title: "Secret Garden",
    description:
      "The Secret Garden painting is a breathtakingly beautiful artwork that transports you to a hidden oasis of peace and serenity. Sun rays filter through the trees, casting a warm and inviting light over the garden. The attention to detail in this painting is impressive, with flowers in every color and shape, growing in abundance. Adding to the charm and character of the garden are the large pots with flowers, decorated with intricate Celtic runes. The garden is meticulously kept, with each leaf and petal carefully placed to create a harmonious and inviting scene.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/9AQ8xPc8PcnFaJi7uM",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PDTM8QAAB2XSN",
    dimension: "30 X 40 X 1.5 cm",
  },
  {    
    src: FingalCave,
    srcBg: FingalCaveBg,
    alt: "Fingal's Aquatic Sonata by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, sunset, sunrise, seachore, ocean, sea, island, celtic, Scotland, Scottish, Nordic, Highlands",
    title: "Fingal's Aquatic Sonata",
    description:
      "Fingal's Aquatic Sonata  transports art enthusiasts to the enchanting beauty of Fingal's Cave, a Scottish natural wonder, in a mesmerizing oil painting characterized by intricate small brushstrokes that breathe life into the canvas. This coastal masterpiece captures the essence of the cave, surrounded by the rhythmic dance of the sea and adorned with the presence of three playful dolphins. The canvas is alive with a vibrant interplay of colors, skillfully applied with small brushstrokes to create texture and depth. The cave, with its natural hexagonal basalt columns, stands as a testament to the geological wonders of the region. The sea, rendered in shades of azure and emerald, ebbs and flows around the cave entrance, creating a dynamic and harmonious backdrop.",
    button: "Card",
    price: "£990",
    link: "https://buy.stripe.com/3cseWdb4L87p18I6qF",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=477GUZ5UZNXRE",
    dimension: "40.5 X 50 X 1.5 cm",
  },
  {    
    src: BowFiddleRock,
    srcBg: BowFiddleRockBg,
    alt: "Bow Fiddle Rock by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, sunset, sunrise, seachore, ocean, sea, island, celtic, Scotland, Scottish, Nordic, Highlands",
    title: "Bow Fiddle Rock",
    description:
      "This painting unveils the breathtaking beauty of the Scottish landscape in a mesmerizing oil painting characterized by meticulous small brushstrokes that weave a tapestry of vivid colors and intricate details. This scenic masterpiece transports viewers to the iconic Bow Fiddle Rock, a coastal marvel on the Scottish shoreline. The canvas bursts with a kaleidoscope of hues as the artist skillfully captures the ever-changing palette of the Scottish sky. Clouds, painted with vibrant strokes, dominate the heavens, creating a dynamic interplay of light and shadow over the rugged landscape (This artwork is part of SSA exhibition at THE MACLAURIN ART GALLERY IN AYR).",
    button: "Reserved",
    price: "You can buy this artwork through SSA",
    link: "https://ssa.viewingrooms.com/content/feature/316/artworks-10339-luna-smith-bow-fiddle-rock/",
    linkPayPal: "https://ssa.viewingrooms.com/content/feature/316/artworks-10339-luna-smith-bow-fiddle-rock/",
    dimension: "30 X 30 X 1.5 cm",
  },
  {    
    src: Paradise,
    srcBg: ParadiseBg,
    alt: "Paradise by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, summer, waves, holiday, sand, sandy, seachore, beach, island, ocean, sea",
    title: "Paradise",
    description:
      "The Paradise painting is a stunning representation of a tropical paradise. The huge cliff rises up, creating a dramatic and awe-inspiring backdrop for the turquoise waters and sandy shore below. The colors in the painting are rich and vibrant, capturing the essence of the island. The use of light and shadow creates a sense of depth and dimension, making the scene feel real and creating a sense of seclusion and peace.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/7sIdS98WDafx04E7uN",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=FE4P3WQDHMCVJ",
    dimension: "50 X 60 X 1.5 cm",
  },
  {    
    src: Savannah,
    srcBg: SavannahBg,
    alt: "Savannah Beach, Georgia by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, sand, sandy, seachore, beach, owl, USA, America, summer, ocean, sea",
    title: "Savannah Beach, Georgia",
    description:
      "The Savannah Beach oil painting is a beautiful depiction of the seaside in Georgia, USA. The grass is in the foreground and is lush and dark green, with a hidden gate welcoming visitors to have a day out. The beach is next, with the sand and shoreline rendered in stunning detail, creating a sense of depth and dimension. The sea is calm and inviting, with gentle waves lapping at the shore. In the background, the sky is painted in shades of blue, capturing the peaceful thoughts of a summer day.",
    button: "Sold",
    price: "£900",
    link: "",
    linkPayPal: "",
    dimension: "51 X 41 X 1.5 cm",
  },
  {    
    src: SnowyMountRainier,
    srcBg: SnowyMountRainierBg,
    alt: "Snowy Mount Rainier by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, blue, scenery, clouds, mountain, skiing, skies, snow, snowy, winter, wintery, USA, Washington, mountains",
    title: "Snowy Mount Rainier",
    description:
      "The Snowy Mount Rainier is a breathtaking representation of one of the most popular skiing destinations in the USA. This painting captures the majestic beauty of the mountain, with its towering peak and snow-covered slopes. The color palette is limited to different shades of blue and white, creating a serene and peaceful atmosphere. The pine trees are depicted as covered in snow, creating a sense of depth and dimension in the painting. Despite being a popular skiing destination, there are no people present in the painting, giving it a feeling of stillness and solitude.",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "40 X 30 X 1.5 cm",
  },
  {    
    src: HawaiianBeach,
    srcBg: HawaiianBeachBg,
    alt: "Hawaiian Beach by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, summer, waves, holiday, sand, sandy, seachore, beach, island, ocean, sea, Hawaii, USA, America",
    title: "Hawaiian Beach",
    description:
      "The Hawaiian beach oil painting depicts a stunning beach scene, with almost golden sandy beaches and deep blue waters. The horizon of the artwork is marked by low grey clouds, which add a sense of depth, atmosphere, and a sense of balance to the art piece. The artist captures the feeling before the storm, evoking the sense of the sound of waves and the breeze.",
    button: "Card",
    price: "£990",
    link: "https://buy.stripe.com/4gw3dv0q7cnF2cM02o",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XRA536GWKJM3E",
    dimension: "51 X 41 X 1.5 cm",
  },
  {
    src: VikingSunset,
    srcBg: VikingSunsetBg,
    alt: "Viking Sunset by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, Norse Mythology, celtic, Scotland, Scottish, Nordic, sea, ocean, waves, summer, sunrise, sunset",
    title: "Viking Sunset",
    description:
      "The Viking Sunset painting is a breathtaking representation of a sunset over the sea. The artwork is dominated by the stunning, fiery sunset, with the sky painted in an array of brilliant hues. The colorful clouds are depicted as covering the entire sky, creating a dramatic backdrop for the sea below. The sea is depicted as a striking shade of blue, with a highlight of the reflecting sunset adding an extra layer of beauty. In the foreground, you can see a sandy wet beach, with the sand and water rendered in fine detail.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/cN25lDfl1drJbNm9CZ",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TTM2YCR54TW6E",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: WinterBreeze,
    srcBg: WinterBreezeBg,
    alt: "Winter Breeze by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, frozen, lake Norse Mythology, snow, snowy, winter, wintery, celtic, Scotland, Scottish, Nordic, forest, woods",
    title: "Winter Breeze",
    description:
      "The Winter Breeze painting, with each stroke capturing the movement of the winter light wind and the delicate beauty of the snowy landscape. The color palette is dominated by soft pastel hues, capturing the stillness and serenity of the cold season. The snow-covered trees surround the frozen lake and create a sense of depth and dimension. The sky is painted in a light hue of a sunrise, adding an extra layer of poetry to the already stunning landscape.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/6oE8xP8WD3R99FebL8",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2BAR7EZDACLP2",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: Waterfall,
    srcBg: WaterfallBg,
    alt: "Waterfall by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, mountain, Norse Mythology, Highlands, celtic, Scotland, Scottish, Nordic, hills, mountains, water",
    title: "Waterfall",
    description:
      "The artwork is dominated by the wild majestic waterfall, which is depicted as cascading down from the top of mysterious hills covered with deep dark green plants. The moody grey hue of the sky creates a sense of depth and dimension in the painting. In the foreground, you can see small grey-black rocks, adding a sense of scale and grounding the waterfall in the environment. You can almost hear the power of the water splashing with an unstoppable force.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/14k9BTfl13R98BaeXl",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YTEQ6W7RN6PVS",
    dimension: "60 X 50 X 1.5 cm",
  },
  {
    src: WinterCottage,
    srcBg: WinterCottageBg,
    alt: "Winter Cottage by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, mountain, cottage, house, holiday, snow, snowy, winter, wintery, celtic, Scotland, Scottish, Nordic, mountains, forest, woods",
    title: "Winter Cottage",
    description:
      "The Winter Cottage painting is a charming representation of a cozy winter retreat. The painting depicts a lonely cottage, perched on top of a snowy mountain, surrounded by a forest of magical, snowy pine trees. The cottage is rendered in warm, inviting colors, creating a sense of comfort and homeliness. The background of the painting features a majestic mountain, covered in snow, and a pristine blue sky, lending a sense of grandeur and scale to the scene.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/eVa9BTgp55ZhbNm02s",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YCAWMWFNH4YHE",
    dimension: "60 X 50 X 1.5 cm",
  },
  {
    src: MeltingAutumnSunrise,
    srcBg: MeltingAutumnSunriseBg,
    alt: "Melting Autumn Sunrise by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, Norse, Scotland, Scottish, sunset, forest, woods, lake, boar, boars, family, animal, animals",
    title: "Melting Autumn Sunrise",
    description:
      "The Melting Autumn Sunrise painting is a beautiful representation of morning scenery. The artwork depicts a mother wild boar and her two young playing in a lush autumn forest, nearby a calm lake. The colors of the painting are rich and vibrant, capturing the warm tones of the sunrise and the colors of the changing leaves. The brushwork is skillful, with each stroke capturing the delicate beauty of the autumn landscape and the playfulness of the wild boar family.",
    button: "Card",
    price: "£990",
    link: "https://buy.stripe.com/fZeeWd1ub9bt3gQ2aB",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=3YA5V4QQBWCSW",
    dimension: "51 X 41 X 1.5 cm",
  },
  {
    src: Autumn,
    srcBg: AutumnBg,
    alt: "Autumn by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, autumn, fall, forest, cottage, hut",
    title: "Autumn",
    description:
      "The stunning autumn forest a small shed nestled among the trees. The painting is dominated by warm, orange hues, reflecting the colors of the autumn leaves and the changing season. The birch trees are filled with plenty of colorful leaves, creating a rich and textured landscape. The small shed is hidden among the trees, lending a sense of mystery and intrigue to the scene. Skillful brushstrokes capture the delicate beauty of autumn and the warm glow of the morning sun.",
    button: "Card",
    price: "£1800",
    link: "https://buy.stripe.com/8wM29rfl1afxeZy9D4",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=UZDC9AYG3DGU2",
    dimension: "50 X 70 X 1.5 cm",
  },
  {
    src: AutumnReflection,
    srcBg: AutumnReflectionBg,
    alt: "Autumn Reflection by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, autumn, fall, woods, trees, forest, river",
    title: "Autumn Reflection",
    description:
      "The Autumn Reflection painting is a stunning representation of autumn in all its glory. The artwork features a river at the center, surrounded by enchanting trees. The greens and blues in the painting create a rich and vibrant color palette, setting it apart from typical landscapes. The midday sun shines down, illuminating the river and creating a stunning reflection of the autumn forest in its waters. Every brushstroke in the painting is carefully crafted to capture the essence of autumn in its full splendor.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/dR615n7Szafx18I9D5",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=3SJXT74KBDYTJ",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: Arran,
    srcBg: ArranBg,
    alt: "Grasslands of Arran by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, grasslands, Higlands, grass, hills, autumn, fall, celtic, Scotland, Scottish, Nordic, island",
    title: "Grasslands of Arran",
    description:
      "The Grasslands of Arran is an oil painting that depicts the stunning beauty of a Scottish landscape. The artwork showcases rolling hills covered in a sea of colorful wild grass, with a small spring providing a tranquil story to the scene. The sun rises in the background, casting a pastel light over the hills and creating a magical, ethereal atmosphere. The warm autumn colors are expertly conveying the majesty and magnificence of this idyllic corner of Scotland.",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: GlenEtive,
    srcBg: GlenEtiveBg,
    alt: "The Song of Glen Etive by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, grasslands, Higlands, grass, hills, autumn, fall, celtic, Scotland, Scottish, Nordic, island",
    title: "The Song of Glen Etive",
    description:
      "The Song of Glen Etive is an oil painting that showcases the raw beauty of the Scottish Highlands. The wind whips through the rough Scottish scenery and creates a haunting song. The colors used in the painting are warm, representing the colors of autumn and adding to the feeling of wildness and untamed beauty. Every brushstroke is visible, conveying the rugged, textured quality of the landscape and allowing the viewer to feel as if they are standing in the heart of the Scotland.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/eVa01j8WD3R93gQ4iM",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9T7HKJKYS5BB8",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: MeltingBlueMountains,
    srcBg: MeltingBlueMountainsBg,
    alt: "Melting Blue Mountains by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, clouds, mountain, Peru, sunset, fields, mountains",
    title: "Melting Blue Mountains",
    description:
      "The Melting Blue Mountains oil painting is a beautiful depiction of a Peruvian landscape. The painting captures the natural beauty of the region, with vibrant green fields and majestic blue mountains set against a sunset sky. The colors in the painting are intense and striking, with a strong contrast between the deep blues of the mountains, the fiery sky, and the lush greens of the fields.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/6oEdS97SzfzRaJi5mR",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TV8RDWZ5RPDVU",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: ItalianAlps,
    srcBg: ItalianAlpsBg,
    alt: "Italian Alps by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, mountains, mountain, snow, snowy, winter, wintery, boat, lake, Switzerland, Italy",
    title: "Italian Alps",
    description:
      "Italian Alps is a beautiful work of art that invites the viewer to experience the timeless beauty of the Italian landscape, and to marvel at the sheer majesty and grandeur of the mountains. The painting captures the essence of the winter season in all its glory, and serves as a testament to the enduring power of nature. The snow-covered mountains in the painting are awe-inspiring, with their towering peaks and rugged terrain adding to the overall sense of natural grandeur. The warm colors of the sky and the sun create a striking contrast against the stark whiteness of the snow, adding to the overall sense of drama and energy in the painting. The frozen lake in the foreground of the painting adds a sense of calmness and tranquility to the scene, with the shimmering bridge and boat creating a sense of harmony and balance. ",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/5kAcO5gp5cnF2cMcPk",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XGUN9VKWBDT22",
    dimension: "50 X 60 X 1.5 cm",
  },
  {
    src: BlueHollow,
    srcBg: BlueHollowBg,
    alt: "The Blue Hollow by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, hills, waterfall, snowy, mountains, river, boats, forest, spring, holiday, celtic, Norse, Higlands",
    title: "The Blue Hollow",
    description:
      "The Blue Hollow captures almost dreamlike ambiance that transports the viewer to another world. The canvas is dominated by the striking image of a mighty hill, towering high into the sky and covered with lush forests and grasses. At the bottom of the hill, a long lake can be seen, stretching far into the distance. The still waters of the lake are a deep and mesmerizing blue, with small white boats gliding across its surface. The serenity of the lake is disrupted by a mighty waterfall, cascading down the hillside and into the lake, creating a sense of drama and energy.",
    button: "Card",
    price: "£1320",
    link: "https://buy.stripe.com/bIY3dv3CjgDV4kU9D9",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=J2EQZ3K5RC6NW",
    dimension: "60 X 50 X 1.5 cm",
  },
  {
    src: ElgolBeach,
    srcBg: ElgolBeachBg,
    alt: "Sunrise at Elgol Beach by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, sea, ocean, rocky beach, sunrise, boats, forest, summer, holiday, celtic, Norse, Higlands",
    title: "Sunrise at Elgol Beach",
    description:
      "The painting depicts a stunning sunrise, with beautiful clouds illuminating the sky with a range of warm colors. The clouds cast a soft, golden light over the rocky beach, highlighting the jagged edges of the rocks and creating a sense of depth and texture. The rocky shoreline, with its contrasting colors and jagged edges, stands in stark contrast to the gentle colors of the sunrise, creating a sense of tension and harmony at the same time.",
    button: "Card",
    price: "£1800",
    link: "https://buy.stripe.com/dR63dvfl1drJ2cMaHe",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7V3WB75K3USQ2",
    dimension: "69 X 50 X 1.5 cm",
  },
  {
    src: AutumnSunset,
    srcBg: AutumnSunsetBg,
    alt: "Autumn Sunset by Luna Smith - oil painting",
    about: "Landscapes",
     keyWords: "landscape, landscapes, scenery, Scotland, Scottish, sunset, sea, grass, tree, autumn, celtic, Norse",
    title: "Autumn Sunset",
    description:
      "Autumn Sunset captures the breathtaking beauty of a sunset during the autumn season. The reflection of the sky in the water creates a stunning effect, making the sea appear almost golden. The grey clouds covering a big portion of the sky are adding sense of drama and contrast to the scene. In the foreground of the painting, a large tree with yellow shimmering leaves can be seen, standing tall against the backdrop of the setting sun. The wild grasses surrounding the tree add to the natural beauty of the scene.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/fZe4hz4Gn5Zh5oY8z7",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4N6HNMFLGNFPU",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: WhenWinterMeetsSummer,
    srcBg: WhenWinterMeetsSummerBg,
    alt: "When Winter Meets Summer by Luna Smith - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, waves, blue, girl, woman, celtic, Norse, sea, ocean, Norse Mythology",
    title: "When Winter Meets Summer",
    description:
      "The painting portrays the meeting of two seasons, winter and summer, in a way that is both calming and enchanting. The woman, sitting at the beach, represents summer and warmth, while the snowy mountain in the distance represents the cold and stillness of winter. The meeting of these two elements creates a harmonious and peaceful environment, where the two seasons blend together seamlessly.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/cN2g0hc8PbjB18I7v4",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=GS4UHAM7G8H84",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: ScottishDream,
    srcBg: ScottishDreamBg,
    alt: "Scottish Dream - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, woods, forest, lake, cottage, hut, sunrise, summer, holiday, celtic, Norse, Norse Mythology",
    title: "Scottish Dream",
    description:
      "Scottish Dream portrays a perfect moment in time, where the beauty of nature is fully appreciated and celebrated. The warmth of the sun, the tranquility of the water, and the natural surroundings all come together to create a dreamy and serene scene that transports the viewer to a world of peace and tranquility. In the foreground of the painting, a small, simple boat with a shed can be seen at the far end of the lake, adding a sense of scale to the immense beauty of the natural surroundings. The boat seems to be a humble addition to the painting, reminding the viewer of the simplicity and peacefulness of life in nature.",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/8wMeWd7Sz2N5aJi9Dd",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=F8GQWE24AMXAA",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: TrappedWind,
    srcBg: TrappedWindBg,
    alt: "Trapped in the Silent Wind - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, Scotland, Scottish, woods, forest, lake, spring, celtic, Norse, Norse Mythology",
    title: "Trapped in the Silent Wind",
    description:
      "Trapped in the Silent Wind captures the essence of springtime at the lake. The canvas is filled with a stunning view of a serene body of water, encircled by lush green forests. The surface of the lake is calm, with small ripples created by the gentle breeze that seems to flow through the canvas. The reflection of the forest in the water creates a mesmerizing effect, almost as if the trees themselves are reaching out to touch the water. The artwork exudes a sense of peace and serenity, and invites the viewer to take a moment to pause and appreciate the natural beauty that surrounds us.",
    button: "Card",
    price: "£495",
    link: "https://buy.stripe.com/aEU3dvc8PbjBg3C3eQ",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=B2THMJ8FR8XXS",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: FirstWalk,
    srcBg: FirstWalkBg,
    alt: "The First Walk - oil painting",
    about: "Landscapes",
    keyWords: "landscape, landscapes, scenery, turtle, baby, seashoe, sandy beach waves, sea, ocean, summer, celtic, Norse, Norse Mythology",
    title: "The First Walk",
    description:
      "The painting depicts a heartwarming scene of a baby turtle making its first journey towards the ocean. The little one's eyes are fixed on the vast expanse of water ahead of it, and its determination to reach the sea is palpable. The painting captures the innocence and vulnerability of the young creature, as it takes its first steps in the world, ready to face the challenges of the vast and unknown ocean.",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "40 X 30 X 1.5 cm",
  },
  {    
    src: MiniWaterfall,
    srcBg: MiniWaterfallBg,
    alt: "Mini Waterfall by Luna Smith - oil painting",
    about: "Still Life",
    keyWords: "water, stone, stones, rocks, bamboo, Japan, still life, realism",
    title: "Mini Waterfall",
    description:
    "This artwork is a tranquil oil painting that captures the essence of peace and balance. In this composition, a gentle stream of water flows gracefully from a bamboo spout, cascading into a calm pool below. Beside the flowing water, a meticulously arranged stack of smooth, dark stones rises, symbolizing stability and harmony. The lush green background evokes a sense of natural serenity, creating a soothing atmosphere. Smith's expert use of texture and color brings the scene to life, allowing viewers to almost hear the gentle trickle of water and feel the calming ambiance of this zen-like setting. This painting beautifully embodies the quiet beauty and meditative qualities of nature. More about the artist at LuArt Gallery.",
    button: "Sold",
    price: "£55",
    link: "",
    linkPayPal: "",
    dimension: "20 X 15 X 1.5 cm",
},
];

