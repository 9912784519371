import SoundOfLife from "../media/artpage/SoundofLife/SoundofLifebyLunaSmith.jpg";
import Harp from "../media/artpage/SoundofLife/HarpbyLunaSmith.jpg";
import NorseSaga from "../media/artpage/SoundofLife/NorseSagabyLunaSmith.jpg";
import MythicalViolin from "../media/artpage/SoundofLife/MythicalViolinbyLunaSmith.jpg";
import PastelViolin from "../media/artpage/SoundofLife/PastelViolinbyLunaSmith.jpg";
import Curiosity from "../media/artpage/SoundofLife/CuriositybyLunaSmith.jpg";
import Duet from "../media/artpage/SoundofLife/DuetbyLunaSmith.jpg";
import Flute from "../media/artpage/SoundofLife/FlutebyLunaSmith.jpg";
import Guitar from "../media/artpage/SoundofLife/GuitarbyLunaSmith.jpg";
import Jazz from "../media/artpage/SoundofLife/JazzbyLunaSmith.jpg";
import Saxophone from "../media/artpage/SoundofLife/SaxophonebyLunaSmith.jpg";
import Saxophones from "../media/artpage/SoundofLife/SaxophonesbyLunaSmith.jpg";
import Sunflowers from "../media/artpage/SoundofLife/SunflowersbyLunaSmith.jpg";
import Trumpet from "../media/artpage/SoundofLife/TrumpetbyLunaSmith.jpg";
import Violins from "../media/artpage/SoundofLife/ViolinsbyLunaSmith.jpg";
import Warriors from "../media/artpage/SoundofLife/WarriorsbyLunaSmith.jpg";
import NordicCrescendo from "../media/artpage/SoundofLife/NordicCrescendobyLunaSmith.jpg";
import SaxophoneSmall from "../media/artpage/SoundofLife/Saxophone-small_Luna_Smith.jpg";
import Piano from "../media/artpage/SoundofLife/PianoByLunaSmith.jpg";
import Jump from "../media/artpage/SoundofLife/Jump.jpg";

import SoundOfLifeBg from "../media/artpage/SoundofLife/SoundofLife.jpg";
import HarpBg from "../media/artpage/SoundofLife/harp.jpg";
import NorseSagaBg from "../media/artpage/SoundofLife/norseSaga.jpg";
import MythicalViolinBg from "../media/artpage/SoundofLife/mythicalViolin.jpg";
import PastelViolinBg from "../media/artpage/SoundofLife/pastelViolin.jpg";
import CuriosityBg from "../media/artpage/SoundofLife/curiosity.jpg";
import DuetBg from "../media/artpage/SoundofLife/duet.jpg";
import FluteBg from "../media/artpage/SoundofLife/flute.jpg";
import GuitarBg from "../media/artpage/SoundofLife/guitar.jpg"
import JazzBg from "../media/artpage/SoundofLife/jazz.jpg";
import SaxophoneBg from "../media/artpage/SoundofLife/saxophone.jpg";
import SaxophonesBg from "../media/artpage/SoundofLife/saxophones.jpg";
import SunflowersBg from "../media/artpage/SoundofLife/sunflowers.jpg";
import TrumpetBg from "../media/artpage/SoundofLife/trumpet.jpg"
import ViolinsBg from "../media/artpage/SoundofLife/violins.jpg";
import WarriorsBg from "../media/artpage/SoundofLife/warriors.jpg";
import NordicCrescendoBg from "../media/artpage/SoundofLife/nordicCrescendo.jpg";
import SaxophoneSmallBg from "../media/artpage/SoundofLife/Saxophone-smallbyLunaSmith-background.jpg";
import PianoBg from "../media/artpage/SoundofLife/Piano-background.jpg";
import JumpBg from "../media/artpage/SoundofLife/Jump_background.jpg";

// import Trial from "../media/artpage/SoundofLife/trial.jpg";
// import TrialBg from "../media/artpage/SoundofLife/trial.jpg";


export const soundOfLifeCollection = [
  {
    src: SoundOfLife,
    srcBg: SoundOfLifeBg,
    alt: "Sound of Life by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "portrait, abstract, music, notes",
    title: "Sound of Life",
    description: "",
    button: "Sold",
    price: "£550",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 3.5 cm",
  },
  {
    src: Harp,
    srcBg: HarpBg,
    alt: "Harp by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "harps, abstract, music, notes, classic, classical music, instrument, strings",
    title: "Harp",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/5kA9BT0q73R918I022",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RURCGPR96NAES",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: NorseSaga,
    srcBg: NorseSagaBg,
    alt: "Norse Saga by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "girl, portrait, woman, book, abstract, music, notes, Norse Mythology, celtic, runes",
    title: "Norse Saga",
    description: "",
    button: "Sold",
    price: "£515",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: MythicalViolin,
    srcBg: MythicalViolinBg,
    alt: "Mythical Violin by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "violins, abstract, music, notes, classic, classical music, instrument, strings",
    title: "Mythical Violin",
    description: "",
    button: "Sold",
    price: "£150",
    link: "",
    linkPayPal: "",
    dimension: "23 X 30 X 1.5 cm",
  },
  {
    src: PastelViolin,
    srcBg: PastelViolinBg,
    alt: "Pastel Violin by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "violins, abstract, music, notes, classic, classical music, instrument, strings",
    title: "Pastel Violin",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/3cscO58WD0EXbNm3ef",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=UQWBEEREWGSCG",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Curiosity,
    srcBg: CuriosityBg,
    alt: "Curiosity by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "girl, portrait, woman, rose, flower, abstract, music, notes, Norse Mythology, celtic, runes",
    title: "Curiosity",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/3csbK1dcT3R92cMbKJ",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=32BN7WCFLELBU",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Duet,
    srcBg: DuetBg,
    alt: "Duet by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "guitar, piano, abstract, music, notes, classic, classical music, instrument, strings",
    title: "Duet",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/bIYdS98WDevNbNmg12",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=DJV3HBHEZUXSS",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Flute,
    srcBg: FluteBg,
    alt: "Flute by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "flutes, abstract, music, notes, classic, classical music, instrument feathers",
    title: "Flute",
    description: "",
    button: "Card",
    price: "£395",
    link: "https://buy.stripe.com/4gw29rb4L0EXbNm15n",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=A3DE3NS6JBMG6",
    dimension: "20 X 60 X 1.5 cm",
  },
  {
    src: Guitar,
    srcBg: GuitarBg,
    alt: "Guitar by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "guitars, flamenco, abstract, music, notes, classic, classical music, instrument",
    title: "Guitar",
    description: "",
    button: "Card",
    price: "£155",
    link: "https://buy.stripe.com/dR6aFXgp53R9dVug0i",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YFECESKZT7278",
    dimension: "25 X 30 X 1.5 cm",
  },
  {
    src: Jazz,
    srcBg: JazzBg,
    alt: "Jazz by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "trumpet, blues, abstract, music, notes, classic, classical music, instrument, man, portrait",
    title: "Jazz",
    description: "",
    button: "Sold",
    price: "£515",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Saxophone,
    srcBg: SaxophoneBg,
    alt: "Saxophone by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "saxophones, abstract, music, notes, classic, classical music, instrument, jazz, blues",
    title: "Saxophone",
    description: "",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Saxophones,
    srcBg: SaxophonesBg,
    alt: "Saxophones by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "saxophones, abstract, music, notes, classic, classical music, instrument, jazz, blues",
    title: "Saxophones",
    description: "",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Sunflowers,
    srcBg: SunflowersBg,
    alt: "Sunflowers by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "sunflower, flower, vase, still life, flowers, abstract, music, notes, classic, classical music, instrument, guitar",
    title: "Sunflowers",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/28o5lD5Krafx4kUbKN",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7R6F32PMLU55U",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Violins,
    srcBg: ViolinsBg,
    alt: "Violins by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "violin, abstract, music, notes, classic, classical music, instrument",
    title: "Violins",
    description: "",
    button: "Card",
    price: "£515",
    link: "https://buy.stripe.com/28oeWdc8P73l3gQ8yv",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=D9ZSFNT69NK8S",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Trumpet,
    srcBg: TrumpetBg,
    alt: "Trumpet by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "blue, abstract, music, notes, classic, classical music, instrument, jazz, blues",
    title: "Trumpet",
    description: "",
    button: "Card",
    price: "£395",
    link: "https://buy.stripe.com/14kbK1b4L2N5dVu6pO",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4M6B5FGR9C65W",
    dimension: "20 X 60 X 1.5 cm",
  },
  {
    src: Warriors,
    srcBg: WarriorsBg,
    alt: "Warriors by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "monk, monks, martial arts, horn, Norse Mythology, abstract, music, notes, classic, classical music, instrument",
    title: "Warriors",
    description: "",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: NordicCrescendo,
    srcBg: NordicCrescendoBg,
    alt: "Nordic Crescendo by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "guitat, abstract, music, notes, classic, classical music, instrument, flamenco, people",
    title: "Nordic Crescendo",
    description: "",
    button: "Card",
    price: "£1150",
    link: "https://buy.stripe.com/8wM5lDc8P3R95oYeX1",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=R2GRQHHWNZMZE",
    dimension: "40.5 X 50 X 1.5 cm",
  },
  {
    src: SaxophoneSmall,
    srcBg: SaxophoneSmallBg,
    alt: "Saxophone by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "saxophones, abstract, music, notes, classic, classical music, instrument, jazz, blues",
    title: "Saxophone - blue",
    description: "",
    button: "Sold",
    price: "£155",
    link: "",
    linkPayPal: "",
    dimension: "20 X 25 X 1.5 cm",
  },
  {
    src: Piano,
    srcBg: PianoBg,
    alt: "Piano by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "symphony, abstract, music, notes, classic, classical music, instrument, jazz, blues",
    title: "Piano",
    description: "",
    button: "Sold",
    price: "£595",
    link: "https://buy.stripe.com/cN27tL4Gn3R9aJi16J",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=X8EWYEFP9L2EA",
    dimension: "40 X 40 X 1.5 cm",
  },
  {
    src: Jump,
    srcBg: JumpBg,
    alt: "Jump by Luna Smith - oil painting",
    about: "Sound of Life",
    keyWords: "guitar, boy, rock, abstract, music, notes, classic, classical music, instrument",
    title: "Jump",
    description: "",
    button: "Sold",
    price: "£145",
    link: "",
    linkPayPal: "",
    dimension: "20 X 30 X 3 cm",
  },
];
